
import { defineComponent } from 'vue'
import AdminPanelNavMenu from '@/components/molecules/AdminPanelNavMenu.vue'
import SortArrow from '@/assets/svg/sort-arrow.svg?inline'
import AnaliticMenuSVG from '@/assets/svg/analitic-menu.svg?inline'
import DefaultPopup from '@/components/molecules/DefaultPopup.vue'
import AlertBlock from '@/components/molecules/AlertBlock.vue'
import Pagination from '@/components/molecules/Pagination.vue'
import AnalyticsNavMenu from '@/components/molecules/AnalyticsNavMenu.vue'
import Select from '@/components/atoms/CustomSelect.vue'

export default defineComponent({
  name: 'SlowMovers',
  data () {
    return {
      page: 1,
      analyticsData: {},
      analyticsMeta: null,
      erroranAlytics: null,
      searchData: '',
      sortData: '',
      isModalDelete: false,
      idInstructor: null,
      dataAllInstructors: [],
      dataLevels: [],
      filters: '',
      filtersLevel: '',
      isMenuActive: false
    }
  },
  components: {
    AdminPanelNavMenu,
    AnalyticsNavMenu,
    SortArrow,
    DefaultPopup,
    AlertBlock,
    Pagination,
    AnaliticMenuSVG,
    Select
  },
  mounted () {
    this.getAnalytics()
    this.getLevels()
    this.getInstructors()
  },
  methods: {
    getAnalytics (params, sort) {
      let searchParam = `?page=${this.page}&n=20&search=`
      if (this.searchData !== '') {
        searchParam = `?page=${this.page}&search=` + this.searchData
      }
      if (sort === undefined) {
        sort = 'id'
      }
      params = searchParam + '&sort=' + sort + this.filters + this.filtersLevel
      this.$store.dispatch('analytics/getAnalyticsStudentsSlowMovers', params).then(
        (res) => {
          this.analyticsData = res.data
          this.analyticsMeta = res.meta
        },
        (error) => {
          this.erroranalytics = error.response.data.errors
        }
      )
    },
    getInstructors () {
      const params = '?n=100'
      this.$store.dispatch('instructors/getInstructors', params).then(
        (res) => {
          this.dataAllInstructors = res.data
        },
        (error) => {
          this.errorInstructors = error.response.data.errors
        }
      )
    },
    getLevels () {
      this.$store.dispatch('general/getLevels').then(
        (res) => {
          this.dataLevels = res.data
        },
        (error) => {
          this.errorInstructor = error.response.data.errors
        }
      )
    },
    handlePage (page) {
      this.page = page
      this.getAnalytics()
    },
    handleOptionSelected (filterKey, selectedOption, callback) {
      const newFilter = `filter[${filterKey}]=${selectedOption.id}`
      const regex = new RegExp(`filter\\[${filterKey}\\]=[^&]*`)
      if (selectedOption.id !== undefined) {
        if (regex.test(this.filters)) {
          this.filters = this.filters.replace(regex, newFilter)
        } else {
          this.filters += `${this.filters.includes('?') ? '&' : '?'}${newFilter}`
        }
      } else this.filters = this.filters.replace(regex, '').replace(/&{2,}/g, '&').replace(/[?&]$/, '')
      if (callback) callback(selectedOption.id)
      this.getAnalytics()
    },
    resetFilters () {
      window.location.reload()
    },
    toggleMenu () {
      this.isMenuActive = !this.isMenuActive
    },
    closeMenu () {
      this.isMenuActive = false
    }
  }
})
